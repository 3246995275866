import React from "react"
import { makeStyles } from "@material-ui/core/styles/index"
import { Container, Grid } from "@material-ui/core/index"
import { injectIntl } from "gatsby-plugin-intl"
import LineTitle from "./LineTitle"
// import Images from "../styles/Images"
import AboutUsCard from "./Cards/AboutUsCard"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
// import { CardActions } from "@material-ui/core"
import Img from "gatsby-image"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme) => ({
  bannerRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  mainContainer: {
    padding: "0 !important",
  },
  container: {
    backgroundColor: "transparent",
  },
  bannerGrid: {
    background: "linear-gradient(to right, #AAB5A8, #E0E3E0)",
    flexDirection: "row",
    ["@media (max-width:600px)"]: {
      paddingBottom: "60px",
    },
  },
  docs: {
    minWidth: "100%",
    justifyContent: "flex-end",
    maxHeight: 600,
    ["@media (max-width:600px)"]: {
      display: "none",
    },
  },
  docMobile: {
    ["@media (min-width:600px)"]: {
      display: "none",
    },
  },

  tooLongtitle: {
    ["@media (max-width:600px)"]: {
      fontSize: "2.4rem",
    },
  },
  tooLongSubheader: {
    color: "white",
    textTransform: "uppercase",
    align: "left",
    marginTop: "20px",
    maxWidth: "550px",
    ["@media (max-width:600px)"]: {
      fontSize: "1.3rem",
    },
  },
  pageTitle: {
    paddingTop: "80px",
  },
  aboutUs: {
    padding: "0 !important",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "120px",
    backgroundColor: "#202020",
    paddingTop: "40px",
  },
  moreButton: {
    paddingLeft: "3px",
    maxWidth: "160px",
    "&:hover": {
      backgroundColor: "rgba(220,220,220,0)",
    },
  },
  expansion: {
    backgroundColor: "transparent!important",
    boxShadow: "none!important",
    border: "none!important",
    "&:before": {
      display: "none!important",
    },
  },
  expansionDetails: {
    padding: "0px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  contentSummary: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  imageHarvard: {
    marginTop: 20,
    width: "180px",
    height: "120px",
  },
  honorText: {
    fontSize: "0.8rem",
    paddingTop: 20,
  },
}))

const About = ({
  intl,
  bannerImg,
  img1,
  img2,
  img3,
  hadeHonor,
  callumHonorHarvard,
  callumHonorTeaching,
  callumExamen,
}) => {
  const classes = useStyles()
  const aboutUsCardData = [
    {
      name: intl.formatMessage({ id: "about.susan.name" }),
      title: intl.formatMessage({ id: "about.susan.title" }),
      img: img1,
      iconName: "fillersStripe",
      text: (
        <p style={{ whiteSpace: "pre-line" }}>
          {intl.formatMessage({ id: "about.susan.description" })}
        </p>
      ),
      id: "susan",
    },
    {
      name: "Callum Faris",
      title: intl.formatMessage({ id: "about.callum.title" }),
      subTitle: intl.formatMessage({ id: "about.callum.subtitle" }),
      img: img2,
      iconName: "faceLiftStripe",
      text: (
        <p style={{ whiteSpace: "pre-line" }}>
          {intl.formatMessage({ id: "about.callum.description" })}
        </p>
      ),
      // moreText: (
      //   <p style={{ whiteSpace: "pre-line" }}>
      //     {intl.formatMessage({ id: "about.callum.readMore" })}
      //   </p>
      // ),
      // honorCallum: (
      //   <>
      //     <br />
      //     <Typography
      //       component="h4"
      //       variant="p"
      //       className={classes.titleAchieve}
      //     >
      //       Achievements
      //     </Typography>
      //     <br />
      //     <Img
      //       fluid={callumHonorHarvard}
      //       className={classes.imageHarvard}
      //       alt={"certificate"}
      //     ></Img>
      //     <Typography
      //       component="p"
      //       variant="p"
      //       className={classes.honorText}
      //       color="textSecondary"
      //     >
      //       Department of Otolaryngology Harvard Medical School - Callum Faris,
      //       M.D. has fulfilled the requirements of professional training as
      //       Clinical Fellow in Facial Plastic and Reconstructive Surgery
      //     </Typography>
      //     <br />
      //     <Img
      //       fluid={callumHonorTeaching}
      //       className={classes.imageHarvard}
      //       alt={"certificate"}
      //     ></Img>
      //     <Typography
      //       component="p"
      //       variant="p"
      //       className={classes.honorText}
      //       color="textSecondary"
      //     >
      //       Award of Excellence in Teaching - Harvard Medical
      //       School/Massachusets Eye and Ear - Training Program in Otolaryngology
      //       - Head and Neck Surgery - award this certificate of appreciation to
      //       - Callum Faris, M.D. for excellence in teaching and mentorship of
      //       recidents
      //     </Typography>
      //     <br />
      //     <Img
      //       fluid={callumExamen}
      //       className={classes.imageHarvard}
      //       alt={"certificate"}
      //     ></Img>
      //     <Typography
      //       component="p"
      //       variant="p"
      //       className={classes.honorText}
      //       color="textSecondary"
      //     >
      //       The International Board For Certification in Facial Plastic and
      //       Reconstructive Surgery - Claus D. Walter, M.D. Award for Academic
      //       Excellence to - Callum Faris, M.D. for Achieving the Highest score
      //       Among the International Candidates on the 2013 Certifying
      //       Examination
      //     </Typography>
      //     <br />
      //   </>
      // ),
      honor2: true,
      callumHonorHarvard: callumHonorHarvard,
      callumHonorTeaching: callumHonorTeaching,
      callumExamen: callumExamen,
      id: "callum",
    },
    {
      name: "Hade Vuyk",
      title: intl.formatMessage({ id: "about.hade.title" }),
      img: img3,
      iconName: "correctiveStripe",
      text: (
        <p style={{ whiteSpace: "pre-line" }}>
          {intl.formatMessage({ id: "about.hade.description" })}
        </p>
      ),
      id: "hade",
      honor1: hadeHonor,
      honorText1:
        "Medal of honor awarded by the Dutch Specialists Association for Throat, Nose, Ear Surgery and Surgery of the Head, Neck area for its Personal Commitment, Education and High Quality in Plastic and Reconstructive Facial Surgery. November 2016.",
    },
  ]
  return (
    <Container maxWidth={"xl"} className={classes.mainContainer}>
      <Grid container spacing={0} className={classes.bannerGrid}>
        <Grid item xs={12} sm={6}>
          <Container>
            <LineTitle
              header={intl.formatMessage({ id: "about.title" })}
              subheader={intl.formatMessage({ id: "about.subHeaderTitle" })}
              className={classes.pageTitle}
              titleStyle={classes.tooLongtitle}
              subHeaderStyle={classes.tooLongSubheader}
            />
          </Container>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.bannerRight}>
          <Img
            fluid={bannerImg}
            alt={intl.formatMessage({ id: "about.doctor" })}
            className={classes.docs}
          />
        </Grid>
      </Grid>
      <Container maxWidth={"xl"} className={classes.cardContainer}>
        <Container maxWidth={"xl"} className={classes.aboutUs}>
          {aboutUsCardData.map((card) => (
            <AboutUsCard
              name={card.name}
              title={card.title}
              subTitle={card.subTitle}
              iconName={card.iconName}
              img={card.img}
              key={card.title}
              id={card.id}
              honor1={card.honor1}
              honorText1={card.honorText1}
              honor2={card.honor2}
              callumHonorHarvard={callumHonorHarvard}
              callumHonorTeaching={callumHonorTeaching}
              callumExamen={callumExamen}
            >
              {card.text}
              {card.moreText && (
                <ExpansionPanel
                  classes={{
                    root: classes.expansion,
                  }}
                >
                  <ExpansionPanelSummary
                    classes={{
                      content: classes.contentSummary,
                      root: classes.rootSummary,
                    }}
                    className={classes.moreButton}
                    expandIcon={
                      <ExpandMoreIcon className={classes.arrowDown} />
                    }
                  >
                    Read more
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.expansionDetails}>
                    {/* <Grid>
                      <Grid item>{card.moreText}</Grid>
                      <Grid item>{card.honorCallum}</Grid>
                    </Grid> */}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
            </AboutUsCard>
          ))}
        </Container>
      </Container>
    </Container>
  )
}

export default injectIntl(About)
